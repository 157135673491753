@font-face {
  font-family: 'Annonce';
  src: url('fonts/Annonce.woff2') format('woff2'),
    url('fonts/Annonce.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AlteHaasGrotesk';
  src: url('fonts/AlteHaasGrotesk.woff2') format('woff2'),
    url('fonts/AlteHaasGrotesk.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AlteHaasGrotesk-Bold';
  src: url('fonts/AlteHaasGrotesk_Bold.woff2') format('woff2'),
    url('fonts/AlteHaasGrotesk_Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl-SemiBold';
  src: url('fonts/SuisseIntl-SemiBold.woff2') format('woff2'),
    url('fonts/SuisseIntl-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

* {
  box-sizing: border-box;
  cursor: none;
}

::-moz-selection {
  color: #0000FF;
}

::selection {
  color: #0000FF;
}

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  overflow: hidden;
  cursor: none;
  opacity: 0;
  background-color: #F4F2FF;
}

#awwwards {
  position: absolute !important;
  left: 2px !important;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  width: 0px;
  overflow: hidden;
}

#awwwards .js-color-bg {
  fill: blue;
}

.cursor {
  width: 22px;
  height: 22px;
  position: fixed;
  z-index: 999;
  top: -999px;
  left: -999px;
  user-select: none;
  pointer-events: none;
  background: rgba(244, 242, 255, 0.3);
  backdrop-filter: blur(3px);
  border: 1px solid #000000;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  border-top-left-radius: 0%;
}

.filter {
  position: absolute;
  left: -999px;
  top: -999px;
}

.relative {
  height: 100%;
  position: relative;
}

.table {
  width: 100%;
  height: 100%;
  display: table;
}

.table .table_cell {
  width: 100%;
  height: auto;
  display: table-cell;
  vertical-align: middle;
}

.table .table_cell.bottom {
  vertical-align: bottom;
}

#website {
  background: #F4F2FF;
}

#website.invert {
  background: #000;
}

#inverted_color {
  width: 100%;
  height: 0%;
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
  pointer-events: none;
  overflow: hidden;
  background: #FFF;
}

#start {
  height: 100vh;
  overflow: hidden;
}

.invert #start {
  background-color: #000;
}

#home {
  width: 100%;
  max-width: calc(100% - 270px);
  height: 100vh;
  position: relative;
  float: left;
  overflow: hidden;
}

.invert #home {
  background: #000;
}

#home .line.right {
  width: 2px;
  height: 0px;
  background-color: #0000FF;
  position: absolute;
  top: 0px;
  right: 0px;
}

.invert #home .line.right {
  background-color: #FFF;
}

#home .header {
  width: 100%;
  height: 200px;
}

#home .header .logo {
  width: 120px;
  height: 130px;
  background-image: url('img/logo.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 30px;
  left: 30px;
  margin-top: 25px;
  opacity: 0;
}

.invert #home .header .logo {
  background-image: url('img/logo_white.png');
}

#home .intro {
  width: 100%;
  height: calc(100% - 400px);
  overflow: hidden;
}

#home .intro .line {
  width: 0px;
  height: 2px;
  background-color: #0000FF;
  position: absolute;
  left: 0px;
}

.invert #home .intro .line {
  background-color: #FFF;
}

#home .intro .line.top {
  top: 0px;
}

#home .intro .line.bottom {
  bottom: 0px;
}

#home .intro .text {
  width: 100%;
  font-family: 'Annonce';
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: 1px;
  padding-left: 30px;
  padding-right: 120px;
  opacity: 0;
}

.invert #home .intro .text {
  color: #FFF;
}

#home .intro .text p {
  margin-top: 0px;
  margin-bottom: 0px;
}

#home .intro .text > div {
  vertical-align: middle;
}

#home .intro .text a {
  height: 42px;
  color: #000;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}

#home .intro .text > a {
  background-repeat: no-repeat;
  background-image: url('img/hover.png');
  background-position-x: 0px;
  background-position-y: calc(100% - 7px);
  background-size: 100% 2px;
  text-shadow: 2px 0 0 #F4F2FF, -2px 0 0 #F4F2FF, 0 2px 0 #F4F2FF, 0 -2px 0 #F4F2FF, 2px 2px #F4F2FF, -2px -2px 0 #F4F2FF, 2px -2px 0 #F4F2FF, -2px 2px 0 #F4F2FF;
}

.invert #home .intro .text > a {
  color: #FFF;
  text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 2px 2px #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000;
}

#home .intro .text > a:hover {
  color: #0000FF;
}

#home .intro .back {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  left: 0px;
  top: 50vh;
  transform: translateY(-50vh);
  pointer-events: none;
  opacity: 0.0;
}

#home .intro .back .span {
  width: 36px;
  height: 36px;
  font-family: 'SuisseIntl-SemiBold';
  font-size: 32px;
  line-height: 1;
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  transform: rotate(-90deg) translate(-50%, -50%);
  overflow: hidden;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #0000FF;
}

.invert #home .intro .back .span {
  -webkit-text-stroke-color: #FFF;
}

#home .intro .back .span_abs {
  width: 36px;
  height: 36px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  transform: rotate(-90deg) translate(-50%, -50%);
  overflow: hidden;
  opacity: 0.0;
}

#home .intro .back .span_abs .arrow {
  width: 36px;
  height: 36px;
  font-family: 'SuisseIntl-SemiBold';
  font-size: 32px;
  line-height: 1;
  text-transform: uppercase;
  color: #0000FF;
  -webkit-text-fill-color: #0000FF;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #0000FF;
}

.invert #home .intro .back .span_abs .arrow {
  color: #FFF;
  -webkit-text-fill-color: #FFF;
  -webkit-text-stroke-color: #FFF;
}

#home .intro .back .span_abs .arrow > div {
  height: 0px;
  overflow: hidden;
  transition: height 0.15s linear;
}

#home .intro:hover .back .span_abs .arrow > div {
  height: 36px;
}

#home .footer {
  width: 100%;
  height: 200px;
  font-family: 'AlteHaasGrotesk-Bold';
  color: #000;
  font-size: 16px;
  position: absolute;
  z-index: 2;
  left: 0px;
  bottom: 0px;
}

#home .footer .line_bottom {
  width: 0%;
  height: 2px;
  background: #000000;
  position: absolute;;
  left: 0px;
  bottom: 0px;
}

#home .footer .table {
  position: relative;
}

#home .footer .invert_color {
  width: 130px;
  height: 16px;
  position: absolute;
  right: 30px;
  bottom: 35px;
  opacity: 0.0;
  overflow: hidden;
  text-transform: uppercase;
}

#home .footer .invert_color:hover {
  color: #0000FF;
}

.invert #home .footer .invert_color {
  color: #FFF;
}

.invert #home .footer .invert_color:hover {
  color: #0000FF;
}

#home .footer .invert_color .text {
  float: left;
}

#home .footer .invert_color .text.white {
  display: none;
}

.invert #home .footer .invert_color .text.white {
  display: block;
}

.invert #home .footer .invert_color .text.black {
  display: none;
}

#home .footer .invert_color .round {
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 100%;
  float: left;
  margin-right: 15px;
  margin-top: 4px;
}

.invert #home .footer .invert_color .round {
  background: #FFF;
}

#home .footer .socials {
  width: 110px;
  opacity: 0.0;
  left: 30px;
  bottom: 30px;
  overflow: hidden;
  position: absolute;
}

.invert #home .footer .socials {
  color: #FFF;
}

#home .footer .socials p {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

#home .footer .social {
  color: #000000;
  text-decoration: none;
  background-repeat: no-repeat;
  background-image: url('img/hover.png');
  background-position: 0px 17px;
  background-size: 100% 2px;
  text-shadow: 1px 0 0 #F4F2FF, -1px 0 0 #F4F2FF, 0 1px 0 #F4F2FF, 0 -2px 0 #F4F2FF, 1px 1px #F4F2FF, -1px -1px 0 #F4F2FF, 1px -1px 0 #F4F2FF, -1px 1px 0 #F4F2FF;
}

.invert #home .footer .social {
  color: #FFF;
  text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

#home .footer .social:hover {
  color: #0000FF;
}

#right {
  width: 100%;
  max-width: 272px;
  height: 100vh;
  position: absolute;
  z-index: 998;
  top: 0px;
  right: 0px;
  font-family: 'AlteHaasGrotesk-Bold';
  font-size: 16px;
  line-height: 1.3;
  background: #F4F2FF;
  overflow: hidden;
}

.invert #right {
  background: #000;
}

#right .line.left {
  width: 2px;
  height: 0px;
  background-color: #0000FF;
  position: absolute;
  top: 0px;
  left: 0px;
}

.invert #right .line.left {
  background-color: #FFF;
}

#right .header {
  width: 270px;
  height: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.0;
  padding: 30px;
}

.invert #right .header {
  color: #FFF;
}

#right .header a {
  color: #000;
  text-decoration: none;
  background-repeat: no-repeat;
  background-image: url('img/hover.png');
  background-position: 0px 17px;
  background-size: 100% 2px;
  text-shadow: 1px 0 0 #F4F2FF, -1px 0 0 #F4F2FF, 0 1px 0 #F4F2FF, 0 -2px 0 #F4F2FF, 1px 1px #F4F2FF, -1px -1px 0 #F4F2FF, 1px -1px 0 #F4F2FF, -1px 1px 0 #F4F2FF;
}

.invert #right .header a {
  color: #FFF;
  text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

#right .header a:hover  {
  color: #0000FF;
}

#right .top .line_bottom {
  width: 0%;
  height: 2px;
  background: #000000;
  display: none;
}

#right .footer {
  width: 270px;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0px;
  opacity: 0.0;
  padding: 30px;
  padding-bottom: 30px;
}

#right .footer a {
  color: #000;
  text-decoration: none;
  background-repeat: no-repeat;
  background-image: url('img/hover.png');
  background-position: 0px 17px;
  background-size: 100% 2px;
  text-shadow: 1px 0 0 #F4F2FF, -1px 0 0 #F4F2FF, 0 1px 0 #F4F2FF, 0 -2px 0 #F4F2FF, 1px 1px #F4F2FF, -1px -1px 0 #F4F2FF, 1px -1px 0 #F4F2FF, -1px 1px 0 #F4F2FF;
}

.invert #right .footer a {
  color: #FFF;
  text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

#right .footer a:hover  {
  color: #0000FF;
}

#right .bottom .line_bottom {
  width: 0%;
  height: 2px;
  background: #000000;
  display: none;
}

#projects {
  width: calc(0% - 0px);
  max-width: calc(100% - 240px);
  height: 100vh;
  position: absolute;
  z-index: 995;
  top: 0px;
  left: 100%;
  margin-left: 120px;
  overflow-x: hidden;
  background: #F4F2FF;
}

.invert #projects {
  background: #000;
}

#projects .header {
  width: calc(100vw - 240px);
  height: 80px;
  position: relative;
  border-bottom: 2px solid #0000FF;
}

.invert #projects .header {
  border-bottom-color: #FFF;
}

#projects .header .triangle {
  width: 30px;
  height: 2px;
  background-color: #0000FF;
  position: absolute;
  left: -15px;
  bottom: 0px;
}

.invert #projects .header .triangle {
  border-color: transparent transparent #FFFFFF transparent;
}

#projects .header .shadow {
  width: 30px;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.001) 0%, #F4F2FF 75%, #F4F2FF 100%);
}

.invert #projects .header .shadow {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.001) 0%, #000 75%, #000 100%);
}

#projects .header .cats {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0px;
  background: #F4F2FF;
}

.invert #projects .header .cats {
  background: #000;
}

#projects .header .cats .cat,
#projects .header .cats .cat_selected {
  font-family: 'Annonce';
  font-size: 16px;
  text-transform: lowercase;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
}

#projects .header .cats .cat_selected {
  color: #0000FF;
}

.invert #projects .header .cats .cat,
.invert #projects .header .cats .cat_selected {
  color: #FFF;
}

#projects .header .cats .cat#cats_team {
  position: relative;
  padding-left: 15px;
  margin-right: 30px;
  padding-right: 15px;
  border-spacing: 15px;

}

.invert #projects .header .cats .cat#cats_team {
  border-left-color: #FFF;
}

.invert #projects .header .cats .cat#cats_team:after {
  background-color: #FFF;
}

#projects .header .cats .cat#cats_home {
  font-size: 28px;
  font-family: 'SuisseIntl-SemiBold';
  padding-top: 20px;
  padding-bottom: 25px;
  border-right: 2px solid #0000FF;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: 15px;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #0000FF;
  display: none;
}

.invert #projects .header .cats .cat#cats_home {
  border-right-color: #FFF;
  -webkit-text-stroke-color: #FFF;
}

#projects .content {
  width: calc(100vw - 240px);
  height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: scroll;
}

#projects .content .list {
  padding-top: 30px;
}

#projects .content .list .item {
  width: 100%;
  height: auto;
  position: relative;
  padding: 0px 30px;
  padding-bottom: 13px;
  text-transform: uppercase;
  pointer-events: none;
  opacity: 0;
}

#projects .content .list .item .img {
  width: 100%;
  padding-bottom: 100%;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: none;
  margin-bottom: 15px;
}

#projects .content .list .item .title {
  font-family: 'Annonce';
  font-size: 62px;
  line-height: 1;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #0000FF;
  overflow: hidden;
  font-kerning: none;
}

.invert #projects .content .list .item .title {
  -webkit-text-stroke-color: #FFF;
}

#projects .content .list .item .title > div {
  overflow: hidden;
  vertical-align: top;
}

#projects .content .list .item .h2 {
  width: 0%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  font-kerning: none;
  -webkit-text-stroke-color: #000;
}

#projects .content .list .item .h2 h2 {
  width: calc(100vw - 300px);
  font-family: 'Annonce';
  font-size: 62px;
  font-weight: inherit;
  line-height: 1;
  color: #0000FF;
  margin-top: 0px;
  margin-bottom: 0px;
}

.invert #projects .content .list .item .h2 h2 {
  color: #FFF;
}

#projects .imgs {
  width: 50vh;
  max-width: 384px;
  height: 50vh;
  max-height: 384px;
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  transition: transform 0.3s ease, -webkit-filter 0.3s ease;
  opacity: 0;
}

#projects .imgs .img {
  width: 0%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
}

#single {
  width: 0px;
  max-width: calc(100% - 238px);
  height: 100%;
  position: absolute;
  z-index: 996;
  top: 0px;
  left: 100%;
  margin-left: 118px;
  border-left: 2px solid #0000FF;
  overflow: hidden;
  background: #F4F2FF;
}

.invert #single {
  background: #000;
  border-left-color: #FFF;
}

#single .content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent;
  overflow-y: scroll;
  overflow-x: hidden;
}

#single .content .slides {
  width: 100%;
  height: auto;
  background: transparent;
}

#single .content .slides .slide {
  width: 100%;
  height: auto;
  background-color: #F4F2FF;
  position: relative;
  z-index: 4;
  margin-bottom: 2px solid #000;
}

.invert #single .content .slides .slide {
  background-color: #000;
}

#single .content .slides .slide:last-child {
  border-bottom: 2px solid #0000FF;
}

.invert #single .content .slides .slide:last-child {
  border-bottom: 2px solid #FFF;
}

#single .content .slides .slide.padding {
  padding: 120px;
  background-origin: content-box;
}

#single .content .slides .slide.transparent {
  z-index: 2;
  min-height: 100vh;
  border-top-width: 0px;
  border-bottom-width: 0px;
}

#single .content .slides .slide.transparent .padding {
  padding: 30px;
}

#single .content .slides .slide.transparent.fixed {
  min-height: 100px;
}

#single .content .slides .slide .image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.0;
}

#single .content .slides .slide .image.padding {
  padding: 120px;
  background-origin: content-box;
}

#single .content .slides .slide img.opacity {
  width: 100%;
  height: auto;
  opacity: 0.0;
}

#single .content .slides .slide > .table {
  height: auto;
  opacity: 0.0;
  pointer-events: none;
}

#single .content .slides .slide > .table.abs {
  height: auto;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1.0;
  display: block;
  pointer-events: auto;
}

#single .content .slides .slide.padding > .table.abs {
  width: calc(100% - 240px);
}

#single .content .slides .slide > .table.chart.abs.black {
  display: none;
}

.invert #single .content .slides .slide > .table.chart.abs.white {
  display: none;
}

.invert #single .content .slides .slide > .table.chart.abs.black {
  display: block;
  color: #FFF;
}

#single .content .slides .slide .text {
  width: 100%;
  height: auto;
  display: table;
  margin-right: auto;
  margin-left: auto;
  font-family: 'AlteHaasGrotesk';
  font-size: 32px;
  letter-spacing: 1px;
  line-height: 1.7;
  padding: 120px;
}

.invert #single .content .slides .slide .text {
  color: #FFF;
}

#single .content .slides .slide .text i,
#single .content .slides .slide .text em {
  font-style: italic;
}

#single .content .slides .slide .text b,
#single .content .slides .slide .text strong {
  font-family: 'AlteHaasGrotesk';
}

#single .content .slides .slide .text a {
  color: #000000;
  text-decoration: underline;
  text-decoration-color: #0000FF;
}

.invert #single .content .slides .slide .text a {
  color: #FFF;
}

#single .content .slides .slide .text a:hover {
  color: #0000FF;
}

#single .content .slides .slide .text img {
  max-width: 100%;
  height: auto;
  display: table;
  margin-left: auto;
  margin-right: auto;
}

#single .content .slides .slide .back {
  font-family: 'SuisseIntl-SemiBold';
  font-size: 32px;
  line-height: 1;
  height: 32px;
  margin-bottom: 15px;
  margin-top: -10px;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000;
  display: none;
}

.invert #single .content .slides .slide .back {
  -webkit-text-stroke-color: #FFF;
}

#single .content .slides .slide h3 {
  font-family: 'Annonce';
  font-size: 32px;
  font-weight: normal;
  line-height: 1.3;
  margin-top: 0px;
  opacity: 0;
  margin-bottom: 0px;
  padding-top: 75px;
}

.invert #single .content .slides .slide h3 {
  color: #FFF;
}

#single .content .slides .slide .infos {
  width: calc(50% - 30px);
  position: absolute;
  left: 30px;
  bottom: 30px;
  opacity: 0;
  padding-right: 0px;
}

#single .content .slides .slide .infos .item {
  width: 100%;
  position: relative;
  overflow: hidden;
}

#single .content .slides .slide .infos .name {
  width: 100%;
  min-height: 42px;
  font-family: 'Annonce';
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 12px;
}

.invert #single .content .slides .slide .infos .name {
  color: #FFF;
}

#single .content .slides .slide .infos .desc {
  width: 100%;
  font-family: 'AlteHaasGrotesk';
  color: #000;
  font-size: 16px;
  text-align: right;
  position: absolute;
  top: 16px;
  right: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 75px;
}

.invert #single .content .slides .slide .infos .desc {
  color: #FFF;
}

#single .content .slides .slide .infos .desc a {
  color: #000000;
  text-decoration: underline;
  text-decoration-color: #0000FF;
}

.invert #single .content .slides .slide .infos .desc a {
  color: #FFF;
}

#single .content .slides .slide .infos .desc a:hover {
  color: #0000FF;
}

#single .content .slides .slide .infos .line {
  width: 100%;
  height: 2px;
  background: #0000FF;
}

.invert #single .content .slides .slide .infos .line {
  background: #FFF;
}

#single .content .ticker-wrap {
  width: 100%;
  height: 168px;
  padding-top: 55px;
  padding-bottom: 55px;
  border-bottom: 2px solid #0000FF;
  position: relative;
  z-index: 4;
  background: #F4F2FF;
  overflow: hidden;
}

.invert #single .content .ticker-wrap {
  background: #000;
  border-bottom: 2px solid #FFF;
}

#single .content .ticker-wrap .ticker {
  position: absolute;
  left: 0px;
  height: 66px;
  display: inline-block;
  white-space: nowrap;
  box-sizing: content-box;
}

#single .content .ticker-wrap .ticker .ticker__item {
  display: inline-block;
  padding-left: 60px;
  color: white;
}

#single .content .ticker-wrap .ticker .ticker__item .title {
  font-family: 'Annonce';
  font-size: 62px;
  line-height: 1;
  color: transparent;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #0000FF;
  overflow: hidden;
  font-kerning: none;
}

.invert #single .content .ticker-wrap .ticker .ticker__item .title {
  -webkit-text-stroke-color: #FFF;
}

#single .content .ticker-wrap .ticker .ticker__item .h2 {
  width: 1px;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  font-kerning: none;
  transition: width 0.15s linear;
  -webkit-font-smoothing: antialiased;
}

#single .content .ticker-wrap .ticker .ticker__item:hover .h2 {
  width: 100%;
}

#single .content .ticker-wrap .ticker .ticker__item .h2 h2 {
  width: calc(100vw - 300px);
  font-family: 'Annonce';
  font-size: 62px;
  font-weight: inherit;
  line-height: 1;
  color: #0000FF;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
}

.invert #single .content .ticker-wrap .ticker .ticker__item .h2 h2 {
  color: #FFF;
}

#single .content .footer {
  min-height: unset;
  height: auto !important;
  background-color: #F4F2FF;
  position: relative;
  z-index: 1;
}

.invert #single .content .footer {
  background-color: #000;
}

#single .content .footer .fixed {
  width: calc(100% - 240px);
  position: fixed;
  z-index: -1;
  bottom: 0;
}

#single .content .footer .text {
  width: 100%;
  height: auto;
  font-family: 'AlteHaasGrotesk';
  font-size: 32px;
  color: #0000FF;
  letter-spacing: 1px;
  line-height: 1.7;
  padding: 120px;
  transform: scale(1);
}

#single .content .footer .text::-moz-selection {
  color: #000;
}

#single .content .footer .text::selection {
  color: #000;
}

#single .content .footer .text.opacity {
  opacity: 0;
  pointer-events: none;
}

#single .content .footer .text a {
  color: #0000FF;
  text-decoration: underline;
  text-decoration-color: #000000;
}

.invert #single .content .footer .text a {
  text-decoration-color: #0000FF;
}

#single .content .footer .text a:hover {
  color: #000000;
}

.invert #single .content .footer .text a:hover {
  color: #FFFFFF;
}

#single .content .footer .text a::-moz-selection {
  color: #000;
}

#single .content .footer .text a::selection {
  color: #000;
}

#single .first {
  width: 100%;
  height: 100vh;
  max-height: 100%;
  padding: 30px;
  position: absolute;
  z-index: 3;
  overflow: hidden;
  pointer-events: none;
}

#single .first .cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  padding: 120px;
}

#single .first .cover .video {
  width: 100%;
  height: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

#single .first .cover .video .video_content {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

#single .first .cover .video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  pointer-events: auto;
  cursor: auto;
}

#single .first .cover .img {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0px;
  top: 0px;
}

#single_related {
  width: 0px;
  max-width: calc(100% - 238px);
  height: 100%;
  position: absolute;
  z-index: 997;
  top: 0px;
  left: 100%;
  margin-left: 118px;
  border-left: 2px solid #000;
  overflow: hidden;
  background: #FFFFFF;
}

.invert #single_related {
  background: #000;
  border-left-color: #FFF;
}

@media only screen and (max-width: 1023px) {

  * {
    cursor: auto;
  }

  .cursor {
    display: none;
  }

  #awwwards {
    display: none;
  }

  #start {
    height: 100%;
    position: absolute;
    z-index: 994;
    overflow-y: scroll;
  }

  #home {
    max-width: 100%;
    width: 100% !important;
    height: auto;
  }

  #home .line.right {
    display: none;
  }

  #home .intro {
    height: auto;
  }

  #home .intro .text {
    width: 100vw;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
  }

  #home .intro .back {
    display: none;
  }

  #home .footer {
    height: 70px;
    position: relative;
    left: unset;
    bottom: unset;
  }

  #home .footer .table_cell.bottom {
    vertical-align: middle;
  }

  #home .footer .socials {
    bottom: unset !important;
    left: unset !important;
    margin-left: 30px;
    position: relative;
  }

  #home .footer .line_bottom {
    display: block;
  }

  #home .footer .invert_color {
    display: none;
  }

  #right {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    top: unset;
    right: unset;
  }

  #right .header,
  #right .footer {
    width: 100%;
    position: relative;
    top: unset;
    right: unset;
  }

  #right .header {
    padding-top: 0px;
    padding-bottom: 20px;
  }

  #right .footer {
    padding-top: 0px;
  }

  #right .line {
    display: none;
  }

  #right .top .line_bottom,
  #right .bottom .line_bottom {
    display: block;
  }

  #right .map {
    display: none;
  }

  #projects,
  #single,
  #single_related {
    max-width: 100%;
    height: 100%;
    left: 100%;
    margin-left: 0px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  }

  #projects .header {
    width: 100vw;
  }

  #projects .header .relative {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  #projects .header .cats {
    width: 600px;
    padding-left: 0px;
    padding-right: 0px;
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  #projects .header .cats .cat#cats_team {
    position: relative;
    right: unset;
    margin-left: 30px;
  }

  #projects .header .cats .cat#cats_home {
    display: block;
  }

  #projects .header .cats .cat#cats_video {
    margin-right: 15px;
  }

  #projects .content {
    width: 100vw;
  }

  #projects .content .list {
    width: 100%;
    max-width: 375px;
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  #projects .content .list .item {
    padding-bottom: 30px;
  }

  #projects .content .list .item .img {
    width: 0%;
    background-size: cover;
    display: block;
    opacity: 0.0;
  }

  #projects .content .list .item .title {
    font-size: 22px;
    -webkit-text-stroke-width: 1px;
  }

  #projects .content .list .item .h2 {
    display: none;
  }

  #projects .imgs {
    display: none;
  }

  #single,
  #single_related {
    border-left-width: 0px;
  }

  #single .first .cover {
    padding: 30px;
    left: 0% !important;
  }

  #single .first .cover > div iframe {
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    position: absolute;
    top: 30px;
    left: 30px;
  }

  #single .content .slides .slide.padding,
  #single .content .slides .slide .image.padding {
    padding-left: 30px;
    padding-right: 30px;
  }

  #single .content .slides .slide.padding > .table.abs {
    width: calc(100% - 60px);
  }

  #single .content .slides .slide .back {
    display: block;
  }

  #single .content .slides .slide h3 {
    font-size: 22px;
    padding-top: 0px;
  }

  #single .content .slides .slide .text {
    font-size: 22px;
    padding: 30px;
  }

  #single .content .slides .slide .infos {
    width: 100%;
    position: relative;
    left: unset;
    bottom: unset;
    padding-right: 0px;
    margin-top: 40px;
  }

  #single .content .ticker-wrap {
    height: 75px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  #single .content .ticker-wrap .ticker {
    height: 26px;
  }

  #single .content .ticker-wrap .ticker .ticker__item {
    padding-left: 30px;
  }

  #single .content .ticker-wrap .ticker .ticker__item .title {
    font-size: 22px;
    line-height: 1;
    -webkit-text-stroke-width: 1px;
  }

  #single .content .ticker-wrap .ticker .ticker__item .h2 {
    display: none;
  }

  #single .content .footer .fixed {
    width: 100%;
  }

  #single .content .footer .text {
    font-size: 22px;
    padding: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

}
